.main-header {

  background: #2d2d2d ;

  // padding: 5px 16px !important;

  height: 48px !important;

  // line-height: 64px !important;

  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  // position: fixed;

  // width: 100%;

  // top: 0;

  border-bottom: 1px solid #656565;

  // overflow: hidden; // Ensure the pseudo-element stays within the header
 
  // &::before {

  //   content: '';

  //   position: absolute;

  //   top: 0;

  //   left: 0;

  //   right: 0;

  //   bottom: 0;

  //   //background-image: url("../../../assets/images/MaskGroup.png"); // Replace with your asset link

  //   //background-size: cover; // or 'contain', depending on your design

  //   //background-repeat: no-repeat;

  //   //opacity: 0.1; // Adjust the opacity here

  //   //z-index: -1; // Send it behind the header content

  // }
 
 
  .header-content {

    display: flex;

    justify-content: space-between;

    align-items: stretch;

    height: 100%;

    margin: 0 auto;

  }
 
  .left-section {

    display: flex;

    align-items: center;

    // gap: 20px;
 
    .logo {

      color: white;

      font-size: 20px;

      font-weight: 400;

      font-family: 'Poppins', Open-Sans !important;

    }
 
    .search-container {

      display: flex;

      align-items: center;

      .search-icon {

        color: rgba(255, 255, 255, 0.65);

        cursor: pointer;
 
        &:hover {

          color: white;

        }

      }

    }

  }

  .center-section {

    .page-title {

      color: white;

      // margin-left: 90px;

      align-self: center;

      font-size: 16px;

      font-weight: 400;

      font-family: 'Poppins', Open-Sans !important;
      margin-left: 55px;
    }

  }

  .right-section {

    display: flex;

    justify-content: center;

    .action-icon {

      color: rgba(255, 255, 255, 0.65);

      font-size: 20px;

      cursor: pointer;

      &:hover {

        color: white;

      }

    }

    .user-info {
      height: 100%;
      border-left: 1px solid #656565;
      margin-top: 0px;
      gap: 19px;
      /* background-color: #333; */
      padding: 7px 12px;
      /* border-radius: 3px; */
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      &.active {
        background-color: #fff;
        padding-right: 18px;
      }
      .user-greeting {
        color: rgba(255, 255, 255, 0.85);
        font-size: 16px;
        font-weight: 350 !important;
        font-family: "Poppins", regular !important;
      }

      .user-avatar {
        background: #1890ff;
        cursor: pointer;
      }
    }

  }

}
