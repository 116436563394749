.modal-header {
    font-size: 20px;
    font-weight: 600 !important;  /* Semi-bold weight */
    font-family: "Poppins" !important;
    color: #333333; /* Dark gray for the title */
    margin-bottom: 25px;
    /* Uncomment the following if you want to add margin or text alignment */
    /* margin-bottom: 20px; */
    /* text-align: center; */
    margin-top: 10px !important;
    margin-left: -20px;
  }
  .create-modal-content {
    padding: 0px 15px 11px 15px;
    /* Padding on all sides */
    background-color: #fff;
    box-sizing: border-box; /* Ensure padding is inside the width */
    // min-height: 200px; /* Minimum height to ensure there's some space */
    // max-height: 341px; /* Limit content height for scrolling */
    // height: 311px;
    // overflow-y: scroll; /* Ensure scrolls if content overflows */
  }
  .modal-content {
    font-size: 14px !important;
    font-weight: 600 !important;  /* Semi-bold weight */
    font-family: "Poppins" !important;
    color: #333333 !important; /* Dark gray for the title */
    padding-top: 16px !important;
    /* Uncomment the following if you want to add margin or text alignment */
    /* margin-bottom: 20px; */
    /* text-align: center; */
  }