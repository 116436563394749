.custom-pagination {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  padding: 12px 0px;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  .pagination-left {
    .selected-count {
      color: #333333;
      font-size: 14px;
      font-weight: 400 !important;
      font-family: 'Poppins' !important;
    }
  }

  .pagination-center {
    display: flex;
    justify-content: center;

    .pagination-controls {
      display: flex;
      align-items: center;
      gap: 12px;

      .pagination-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        padding: 0;
        border: none;
        border-radius: 4px;
        color: #374151;
        background: transparent;

        svg {
          width: 16px;
          height: 16px;
        }

        &:disabled {
          color: #D1D5DB;
          background: transparent;
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background: #F9FAFB;
        }
      }

      .page-info {
        display: flex;
        align-items: center;
        gap: 8px;

        .page-input {
          width: 48px;
          height: 32px;
          padding: 4px 8px;
          border: 1px solid #E5E7EB;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          color: #374151;

          &::-webkit-inner-spin-button,
          &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          &:focus {
            outline: none;
            border-color: #2563EB;
            box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
          }
        }

        .page-arrow {
          color: #6B7280;
          font-size: 14px;
        }
      }
    }
  }

  .pagination-right {
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: flex-end;

    .page-count {
      color: #000000;
      font-size: 14px;
      //min-width: 80px;
    }

    .rows-per-page {
      display: flex;
      align-items: center;
      gap: 12px;
      color: #000000;
      font-size: 12px;
      font-weight: 400 !important;
      font-family: 'Poppins' !important;

      .page-size-select {
        width: 64px;
        .ant-select-selector {
          //border-color: #E5E7EB !important;
          height: 32px !important;

          .ant-select-selection-item {
            line-height: 30px !important;
            color: #374151;
          }
        }
      }
    }
  }
}

.rows-per-page{
  .ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
  }
}
.custom-pagination .pagination-center .pagination-controls .page-info .page-arrow {
  display: none;
}