.assessment-player {
    background-color: #fff;
    //min-height: 100vh;

    &__content {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;
        padding-top: 34px;
        @media (max-width: 1024px) {
            width: 85%;
        }
    }

    &__header {
        margin-bottom: 24px;
    }

    &__date {
        font-size: 14px;
        color: #333333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
    }

    &__title {
        font-size: 24px;
        color: #333333;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-top: 24px;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 12px;
        font-size: 14px;
        color: #333333;
        font-family: 'Poppins', sans-serif;

        p {
            font-weight: 400;
            strong {
                font-weight: 500;
            }
        }
    }

    &__divider {
        margin: 34px 0px;
    }

    &__description {
        font-size: 14px;
        color: #333333;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 32px;
        font-weight: 400;
        line-height: 20px;
    }

    &__stats {
        display: flex;
        gap: 24px;
        margin-bottom: 34px;
    }

    &__stat {
        &-card {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 120px;
            height: 120px;
            border-color: #cacaca;
            border-radius: 6px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &-icon {
            width: 28px;
            height: 34px;
            margin-top: 27px;
            margin-bottom: 20px;
        }

        &-value {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            text-align: center;
            font-family: 'Poppins', sans-serif;
            margin-top: 16px;
            line-height: 20px;
            width: max-content;
        }
    }

    &__warning {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 34px;

        &-icon {
            width: 24px;
            height: 21px;
            color: #F80000;
        }
        &-msg {
            font-size: 14px;
            font-weight: 400;
        }
    }

    &__start-button {
        width: 100%;
        height: 48px;
        background: #fa9b31;
        border: none;
        border-radius: 6px;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;

        &:hover {
            background: #e88d2c !important;
        }
    }
}