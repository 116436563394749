
.assessment-library-page {

  .ant-layout {
    //background: transparent !important;
    @media (max-width: 768px) {
      width: fit-content !important;
    }

    @media (min-width: 1024px) {
      //width: fit-content !important;
    }
  }


  .ant-card-head {
    border-bottom: none !important;
    padding: 0 !important;
  }

  .ant-card-body {
    padding: 16px !important;
  }

  .ant-table {
    background: white !important;
    border-radius: 8px !important;
  }

  .ant-table-thead > tr > th {
    background: #fafafa !important;
    font-weight: 600 !important;
  }

  .assessment-table {
    /*background: white;*/
    border-radius: 8px;
    /*padding: 24px;*/
    /*box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);*/

    .ant-table{
      border-radius: 8px !important;
      border: 1px solid #CACACA;
    }
  }


  .ant-dropdown-menu {
    padding: 4px !important;
  }

  .ant-dropdown-menu-item {
    //padding: 8px 12px !important;
    width: 140px;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    margin: 0;
  }

  .ant-pagination-options {
    margin-left: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .ant-pagination-options-quick-jumper {
    margin-right: 8px;
  }

  .ant-pagination-options-size-changer {
    margin: 0;
  }

  // .ant-checkbox-inner {
  //   border-radius: 50% !important;
  //   width: 20px;
  //   height: 20px;
  // }

  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: #f5a623;
  //   border-color: #f5a623;
  // }

  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: black;
  // }

  // .ant-checkbox-checked:hover .ant-checkbox-inner {
  //   background-color: black;
  // }

  // .ant-checkbox-inner::after {
  //   border-radius: 50%;
  //   width: 16px;
  //   height: 16px;
  // }

  // .ant-checkbox .ant-checkbox-inner{
  //   width: 16px;
  //   height: 16px;
  //   border: 1px solid #333333 !important;
  // }

  .ant-table-wrapper .ant-table-column-sorter{
    display: none;
  }

  .ant-table-column-title {
    font-family: 'Poppins', Open-Sans !important;
    font-size: 16px;
    font-weight: 500;
  }

  .ant-table-cell {
    font-family: 'Poppins', Open-Sans !important;
    font-size: 16px;
    font-weight: 400 !important;
    span {
      font-family: 'Poppins', Open-Sans !important;
      font-size: 16px;
      font-weight: 400 !important;
    }
  }

  .ant-table-thead {
    background-color: yellow !important;
  }

  .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    display: none !important;
  }

  .ant-table-wrapper table tr th.ant-table-selection-column, .ant-table-wrapper table tr td.ant-table-selection-column, :where(.css-dev-only-do-not-override-1edvkb7).ant-table-wrapper .ant-table-selection-column{
    padding-inline-end: 20px !important;
    padding-inline-start: 20px !important;
  }

  .even-row, .odd-row {
    height: 48px !important;
    td {
      border: 1px solid transparent;
      padding: 10px;
    }
  }

  .even-row {
    background-color: #F7F7F7;
    height: 48px !important;
    line-height: 0px;
    padding: 0px !important;
  }

  .odd-row {
    background-color: #FFFFFF;
    height: 0px;
    line-height: 0px;
    padding: 0px !important;
  }

  .even-row td {
    border-top: 1px solid #CACACA;
  }

  .odd-row td {
    border-top: 1px solid #CACACA;
  }

  .odd-row td {
    padding: 0;
    line-height: -20px;
  }

  .odd-row td > div {
    padding: -30px;
    display: block;
  }

  .even-row td > div {
    padding: -30px;
    display: block;
  }

  .assessment-table .ant-table {
    border-radius: 6px !important;
  }
  .tag-select-auto-height .ant-select-selector {
    height: auto !important;
    min-height: 32px;
    padding-bottom: 4px !important;
  }

  .tag-select-auto-height .ant-select-selection-overflow {
    flex-wrap: wrap;
  }

  .tag-select-auto-height .ant-select-selection-item {
    margin-top: 4px;
  }

  .table-cell-content {
    white-space: normal !important;
    word-wrap: break-word !important;
    line-height: 1.5 !important;
    height: 24px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .table-cell-content_new {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
  }


  .ant-table-wrapper .ant-table-column-title{
    font-weight: 600 !important;
  }

  .ant-table-thead > tr > th {
    background: white !important;
    font-weight: 600 !important;
    padding: 16px 20px !important;
    border-bottom: 1px solid #CACACA !important;
    transition: background 0.2s ease;

    &:first-child {
      border-top-left-radius: 8px !important;
    }

    &:last-child {
      border-top-right-radius: 8px !important;
    }
  }

  .ant-table-tbody > tr > td {
    max-height: 48px !important;
    padding-top: 0 !important;
    padding-bottom:0 !important;
  }
}


.custom_table_new {
  .ant-table-tbody > tr > td {
    max-height: 48px !important;
    padding-top: 0 !important;
    padding-bottom:0 !important;
  }

  // .ant-checkbox-inner {
  //   border-radius: 50% !important;
  //   width: 24px;  
  //   height: 24px;
  //   border: 1px solid black !important;
  // }

  // .ant-checkbox-checked .ant-checkbox-inner {
  //   background-color: #f5a623; 
  //   border-color: #f5a623 !important; 
  // }

  // .ant-checkbox-inner::after {
  //   transform: rotate(45deg) scale(0) !important;
  //   position: absolute;
  //   left: 0% !important;
  //   top: 0% !important;
  //   width: 16px !important;
  //   height: 16px !important;
  //   border-radius: 0px !important;
  // }

}

/* Custom styles for Ant Design DatePicker */
.ant-picker-suffix .anticon {
  display: none !important;
}

.ant-picker-suffix .custom-calendar-icon {
  display: flex;
  align-items: center;
  color: #666;
}

/* Hide default separator */
.ant-picker-range-separator {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Custom separator styles */
.custom-separator {
  padding: 0 8px;
  color: #666;
}

.form-item-container .ant-select .ant-picker-range-separator, .form-item-container .ant-picker .ant-picker-range-separator{
  margin-right: 40px;
}


.dropdown-item {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  margin-left: 7px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-bottom: 1px solid #CACACA !important;
  width: 90% !important;
  color: #333333;
  font-size: 14px;

  &:hover {
    //background-color: #F5F5F5;
  }

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;
    margin-left: -7px;
  }
}

.last-dropdown-item {
  padding: 12px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  //border-bottom: 1px solid red !important;
  width: 100% !important;
  color: #333333;
  font-size: 14px;

  &:hover {
    //background-color: #F5F5F5;
  }

  &:last-child {
    border-bottom: none;
  }

  svg {
    width: 16px;
    height: 16px;
    margin-right: 12px;

  }
}

.ant-table-cell {
  padding: 12px 16px !important;
}

.ant-dropdown {
  .ant-dropdown-menu {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border: 1px solid #EAEAEA;
    width: 100% !important;
  }

  .ant-dropdown-menu-item {
    padding: 0;
    margin: 0;
    width: 100% !important;
    &:hover {
      background-color: transparent;
    }
  }
}

.ant-table-thead {
  .ant-table-cell {
    background-color: white !important;
    &::before {
      background-color: transparent !important;
    }
  }
}