/* modalStyles.css */

.existing-modal-content {
  // padding: 20px; /* Padding on all sides */
  background-color: #fff;
  box-sizing: border-box;
  /* Ensure padding is inside the width */
  min-height: 200px;
  /* Minimum height to ensure there's some space */
  max-height: 85vh;
  /* Limit content height for scrolling */
  overflow-y: scroll;
  /* Ensure scrolls if content overflows */
}

/* Hide scrollbars initially */
.existing-modal-content::-webkit-scrollbar {
  width: 8px;
  /* Adjust scrollbar width if desired */
  display: none;
  /* Hide scrollbar */
}

/* Show scrollbar when user scrolls */
.existing-modal-content:hover::-webkit-scrollbar {
  display: block;
  /* Show scrollbar on hover */
}

/* For Firefox */
.existing-modal-content {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  /* Hide scrollbar in Firefox */
}

.existing-modal-content:hover {
  scrollbar-color: #888 #fff;
  /* Show scrollbar color on hover */
}

.modal-header {
  font-size: 20px;
  font-weight: 600 !important;
  /* Semi-bold weight */
  font-family: "Poppins" !important;
  color: #333333;
  /* Dark gray for the title */
  padding-bottom: 5px;
  padding-left: 23px;
  margin-top: 20px;
  /* Uncomment the following if you want to add margin or text alignment */
  /* margin-bottom: 20px; */
  /* text-align: center; */
}

.modal-content {
  font-size: 14px;
  font-weight: 600 !important;
  /* Semi-bold weight */
  font-family: "Poppins" !important;
  color: #333333;
  /* Dark gray for the title */
  padding-top: 16px;
  /* Uncomment the following if you want to add margin or text alignment */
  /* margin-bottom: 20px; */
  /* text-align: center; */
}

.modal-assessment-filter {
  padding-top: 16px;
  /* margin-left: 23px; */
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  gap: 10px;
  box-sizing: border-box;
  justify-content: center;
}

.modal-assessment-filter>* {
  // flex: 1; /* This ensures all children take equal width */
  // min-width: 0; /* Prevent items from shrinking too small */
}

/* RightAlignedButton.css */
.right-aligned-container-existing {
  display: flex;
  justify-content: flex-end;
  // padding-top: 20px;
  margin-bottom: 25px;
}

// :where(.css-dev-only-do-not-override-13s7l06).ant-modal-root .ant-modal-centered .ant-modal {
//   top: 0;
//   display: inline-block;
//   margin-bottom: 25px;
//   margin-top: 25px;

//   text-align: start;
//   vertical-align: middle;
// }