.assessment-filters {

}

.form-item-container-tag {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  // width: 329px !important;

  .ant-select {
    // width: 329px !important;
    // margin-top: 8px !important;
  }
}

.form-items-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  //width: 100%;
}
.modal-form-items-container {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%; /* Ensures container takes the full width */
}

.modal-form-items-container > * {
  flex: 1 1 0%; /* Make all items take equal width */
  min-width: 220px; /* Set a minimum width to prevent too small items on narrow screens */
}
.assessment-filters .form-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  div {
    //width: 230px !important;
    //margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Open-Sans !important;
    margin-bottom: 0px;
  }

  .ant-select {
    width: 230px !important;
    margin-top: 8px;
  }
  .ant-picker {
    width: 436px !important;
    margin-top: 8px;
  }

  .ant-select,
  .ant-picker {
    height: 46px;

    .ant-select-selector {
      height: 46px;
      display: flex;
      align-items: center;
      background: white;
    }

    .ant-picker-input {
      height: 46px;
      display: flex;
      align-items: center;

      input {
        height: 46px;
        line-height: 46px;
        background: white;
      }
    }

    .ant-picker-range-separator {
      height: 46px;
      display: flex;
      align-items: center;
      //margin-right: 0px !important;
    }

    .ant-picker-suffix {
      height: 46px;
      display: flex;
      align-items: center;
    }
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-placeholder {
    position: absolute;
    top: 40%;
    inset-inline-start: 8px;
    inset-inline-end: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
}

.ant-select-arrow {
  span {
    svg {
      color: #333333;
    }
  }
}

.ant-input-affix-wrapper {
  padding: 16px 16px;
}

.ant-modal-body .form-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  div {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', Open-Sans !important;
  }

  .ant-select,
  .ant-picker {
    width: 100%;
    height: 46px;

    .ant-select-selector {
      height: 46px;
      display: flex;
      align-items: center;
      background: white;
    }

    .ant-picker-input {
      height: 46px;
      display: flex;
      align-items: center;

      input {
        height: 46px;
        line-height: 46px;
        background: white;
        padding-top: 13px !important;
      }
    }

    .ant-picker-range-separator {
      height: 46px;
      display: flex;
      align-items: center;
    }

    .ant-picker-suffix {
      height: 46px;
      display: flex;
      align-items: center;
    }
  }

  .ant-select-multiple.ant-select-lg .ant-select-selection-placeholder {
    position: absolute;
    top: 40%;
    inset-inline-start: 8px;
    inset-inline-end: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
  }
  .ant-select-selection-overflow {
    margin-top: 0px !important;
  }

}


