.selected_span {
    background-color: aqua;
    border: 1px solid black;
}

.highlighted-text {
    background-color: yellow;
    /* font-weight: bold; */
}

.correct-answer .highlighted-text {
    background-color: transparent !important;
    cursor: pointer;
    border-radius: 3px
}

.correct-answer .highlighted-text.is-correct {
    background-color: aqua !important;
}

.correct-answer .highlighted-text:hover {
    opacity: 0.5;
    background-color: aqua;
    border: 1px solid black;
}