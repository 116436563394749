.stats-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background: #2d2d2d !important;
  height: 215px;
  position: relative;
  background-image: url("../../../assets/images/studio/bg.svg") !important;
  background-size: cover;
  background-repeat: no-repeat;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("../../../assets/images/studio/bg.svg") !important;
    background-size: cover;
    //background-repeat: no-repeat;
    opacity: 0.5;
    z-index: -1;
  }
}


.text-row {
  width: 130px;
  height: 48px;
  margin-top: 44px;
  margin-bottom: 44px;
  box-shadow: none;
  border-radius: 6px;
  padding: 0;
  line-height: 55px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', Open-Sans !important;
  letter-spacing: 1px;
}

.cards-row {
  display: flex;
  justify-content: center;
  width: 100%;
  //margin-top: 20px;
}

.stats-card {
  border-radius: 6px !important;
  padding: 20px !important;
  width: 180px;
  height: 140px;
  cursor: pointer;
  .ant-col ant-col-3{
    flex: none !important;
  }
  //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !important;

  &.active {
    padding: 20px 20px 14px 20px !important;
    border-bottom: 6px solid #f5a623 !important;
    cursor: pointer;
  }
}

.stats-content {
  text-align: start;
}

.stats-title {
  margin: 0 !important;
  color: #262626 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: 'Poppins' !important;
}

.stats-subtitle {
  margin: 0 !important;
  margin-top: 14px !important;
  color: #333333 !important;
  font-size: 26px !important;
  font-weight: 400 !important;
  font-family: 'Poppins', Open-Sans !important;
}
