// Colors
$primary-color: #fa9b31;
$secondary-color: #333333;
$border-color: #e1e1e1;
$background-light: #f7f7f7;
$shadow-color: #00000029;
$green-status: #22c55e;
$blue-status: #3b82f6;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer {
  &__content {
    width: 100px;
    height: 100px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__circle-bg {
    fill: none;
    stroke: #f5f5f5;
  }

  &__circle-progress {
    fill: none;
    stroke: $primary-color;
    transform-origin: center;
    transition: stroke-dashoffset 1s linear;
    // Add smooth animation
    animation: rotate 1s linear infinite;
    animation-play-state: paused;
  }

  &__text {
    position: relative;
    z-index: 1;
    font-weight: normal;
    color: $secondary-color;
    font-size: 18px;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}