.auth-page {
    .answertblclass table {
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-collapse: collapse;
}
.answertblclass {
  display: flex;
  justify-content: center;
}

.answertblclass tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
}

.question-preview table {
  border: #888 1px solid;
}

.question-preview tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
}

.jodit-wysiwyg img{
  display: inline !important;
}

.display-content table, #radio table, #block table, #checkbox table, #cms table {
  border: #888 1px solid;
}

.display-content tr td, #radio tr td, #block tr td, #checkbox tr td, #cms tr td  {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
  height:40px;
}

.bg-light-accent table {
  border: #888 1px solid;
}

.bg-light-accent tr td {
  border-right: grey 1px solid;
  border-bottom: grey 1px solid;
  padding: 0.4em;
  height:40px;
}

.dropdown-content{
  z-index:1000 !important;
}

.ant-layout {
  background: transparent !important;
}


.ant-card-head {
  border-bottom: none !important;
  padding: 0 !important;
}

.ant-card-body {
  padding: 16px !important;
}

.ant-table {
  background: white !important;
  border-radius: 8px !important;
}

.ant-table-thead > tr > th {
  background: #fafafa !important;
  font-weight: 600 !important;
}

//.action-buttons {
//  padding: 16px 0px;
//  border-radius: 6px;
//  margin-bottom: 16px;
//  border: 1px solid #CACACA;
//}
//
//.action-buttons .ant-btn {
//  font-size: 14px !important;
//  font-family: 'Poppins', sans-serif !important;
//}



.assessment-table {
  border-radius: 8px;
  /* .ant-table{
    border-radius: 8px !important;
    border: 1px solid #CACACA;
  } */
}


.ant-dropdown-menu {
  padding: 4px !important;
}

.ant-dropdown-menu-item {
  padding: 8px 12px !important;
}
.ant-pagination-prev,
.ant-pagination-next {
  margin: 0;
}

.ant-pagination-options {
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.ant-pagination-options-quick-jumper {
  margin-right: 8px;
}

.ant-pagination-options-size-changer {
  margin: 0;
}

.ant-checkbox-inner {
  border-radius: 50% !important;
  width: 20px;  /* Adjust the size as needed */
  height: 20px; /* Adjust the size as needed */
  border: 1px solid black !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #f5a623; /* Change color when checked */
  border-color: #f5a623 !important; /* Border color when checked */
}

.ant-checkbox-inner::after {
  /* border-radius: 50%; */
  transform: rotate(45deg) scale(0.88) !important;
  left: 34% !important;
  top: 16% !important;
}

.ant-checkbox-label {
  padding-left: 16px !important;
}

.ant-input-status-success {
  background-color: #FFFFFF !important;
}

.ant-picker-separator {
  color: #333333 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:focus-visible, 
input:-webkit-autofill:focus-within, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #333333;
    background-color: #FFFFFF !important;
    -webkit-box-shadow: 0 0 0px 1000px #FFFFFF inset !important;
}


:where(.css-dev-only-do-not-override-1edvkb7).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, :where(.css-dev-only-do-not-override-1edvkb7).ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
  display: none !important;
}

:where(.css-dev-only-do-not-override-1edvkb7).ant-table-wrapper table tr th.ant-table-selection-column, :where(.css-dev-only-do-not-override-1edvkb7).ant-table-wrapper table tr td.ant-table-selection-column, :where(.css-dev-only-do-not-override-1edvkb7).ant-table-wrapper .ant-table-selection-column{
  padding-inline-end: 20px !important;
  padding-inline-start: 20px !important;
}
      
}