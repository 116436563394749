// Variables
$primary-color: #FA9B31;
$secondary-color: #333333;
$border-color: #e1e1e1;
$background-light: #f7f7f7;
$shadow-color: #00000029;
$green-status: #53B51B;
$blue-status: #31AAFA;
$amethyst-status: #702FBA;

// Mixins
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-start {
  display: flex;
  align-items: center;
  justify-content: start;
}

@mixin status-variant($background, $color, $border) {
  background-color: $background;
  color: $color;
  border-color: $border;
}

// Main container
.assessment-container {
  display: flex;
  flex-direction: column;
  background-color: white;
  //height: 100vh;
  height: calc(100% - 70px);
  overflow: hidden;

  .assessment-content {
    display: flex;
    flex: 1;
    position: relative;
    overflow-y: hidden;
    min-height: 0;

  }
}


// Sidebar styles
.sidebar {
  //width: 312px;
  position: relative;
  background-color: $background-light;
  border-right: 1px solid $border-color;

  &__content {
    height: 100vh;
    position: relative;
  }

  &__content-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $background-light;
    z-index: -1;
  }

  &__title {
    position: relative;
    width: 298px; // Match sidebar width
    //top: 8px;
    left: 10px;
    background-color: $background-light;
    z-index: 2; // Keep above scrolling content
    padding: 15px 0px;
    font-weight: 500;
    color: $secondary-color;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;
    &-skipped {
      color: $blue-status;
    }
    &-flagged {
      color: $amethyst-status;
    }
    span {
      margin-right: 10px;
    }
  }

  &__header-divider {
    margin-bottom: 10px;
  }

  &__questions {

    height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    margin-top: 15px;
    margin-bottom: 50px;
    &-wrapper {
      min-height: 100%;
      //padding-right: 10px;
      box-sizing: content-box;
      margin-bottom: 120px;
      margin-top: 8px;
    }
    /* Customize scrollbar for WebKit browsers */
    &::-webkit-scrollbar {
      width: 4px; /* Width of the scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1; /* Background of the scrollbar track */
      border-radius: 10px; /* Rounded corners */
    }

    &::-webkit-scrollbar-thumb {
      background: #C8C8C8; /* Color of the scrollbar thumb */
      border-radius: 10px; /* Rounded corners */
      height: 100px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #C8C8C8; /* Color of the scrollbar thumb on hover */
    }
  }
}

// Question card styles
.question-item {
  position: relative;
  margin-bottom: 5px;

}

.question-card {
  width: 300px;
  height: 40px;
  cursor: pointer;
  border-color: $border-color;
  border-radius: 6px;
  .ant-card-body {
    padding: 6px 4px 16px 0 !important;
    width: 100% !important;
  }

  display: flex;
  align-items: center;
  padding: 0px !important;

  &--active {
    //box-shadow: 0px 3px 3px $shadow-color;
  }

  &__content {
    @include flex-center;
    height: 100%;
    flex: 1;
    position: relative;
  }

  &__number {
    width: 60px;
    height: 48px;
    //@include flex-center;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-weight: normal;
    color: $secondary-color;
    font-size: 18px;
    position: relative;
    margin-top: 10px;
    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: -1px;
      height: 40px;
      width: 2px;
      background-color: #E1E1E1;
      margin-top: 5px;
    }
  }

  &__text {
    height: 50px;
    font-weight: 500;
    color: $secondary-color;
    font-size: 14px;
    padding-left: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
  }

  &__flag {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fa8c16;
    font-size: 0.875rem;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 10px;
  }
}

// Status indicator
.status-indicator {
  position: absolute;
  width: 4px;
  height: 35px;
  left: -6px;
  top: 3px;
  border-radius: 10px;

  &--answered {
    background-color: $green-status;
  }

  &--visited {
    background-color: $blue-status;
  }

  &--current {
    background-color: $primary-color;
  }

  &--flagged {
    background-color: $amethyst-status;
  }

}

// Main content area
.main-content {
  flex: 1;
  padding: 24px 58px;
  display: flex;
  flex-direction: column;

  /* Customize scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb {
    background: #C8C8C8; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
    height: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #C8C8C8; /* Color of the scrollbar thumb on hover */
  }

  &__header {
    position: fixed;
    top: 100px;
    left: 434px;
    right: 0;
    margin-right: 65px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    //margin-bottom: 24px;
  }

  &__divider {
    margin-top: 16px !important;
    margin-bottom: 10px !important;
  }
}

// Progress section
.progress-section {
  width: 602px;

  &__text {
    text-align: center;
    margin-bottom: 12px;
    font-weight: normal;
    color: $secondary-color;
    font-size: 14px;
    line-height: 28px;
  }

  &__bar {
    height: 8px;
  }
}

// Timer
.timer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    color: $secondary-color;
    font-size: 18px;
    z-index: 1;
  }
}


// Question content
.question-content {
  flex: 1;
  margin-top: 128px;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 7px !important;

  /* Customize scrollbar for WebKit browsers */
  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
    border-radius: 10px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb {
    background: #C8C8C8; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
    height: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #C8C8C8; /* Color of the scrollbar thumb on hover */
  }

}

.question-number {
  font-size: 24px;
  color: $secondary-color;
  margin-bottom: 16px;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.question-text {
  font-size: 18px;
  color: $secondary-color;
  margin-bottom: 32px;
  line-height: 28px;
}

// Answer options
.answer-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.answer-option-item {
  .option-card {
    width: 70%;
    height: 48px;
    transition: all 0.2s;
    padding: 0px !important;

    .ant-card-body {
      padding: 0px !important;
    }

    &--selected {
      border-color: $primary-color;
      background-color: #fff8f0;
    }

    &__content {
      @include flex-start;
      height: 100%;
      width: 70%;
    }

    &__block {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      padding: 16px;
    }

    &__radio {
      width: 48px;
      height: 48px;
      @include flex-center;
    }

    &__text {
      font-weight: 500;
      color: $secondary-color;
      font-size: 16px;
      margin-left: 15px;
    }
  }
}

// Navigation
.navigation {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0px 7px 24px 0px;

  .btn {
    width: 112px;
    height: 48px;
    font-weight: 500;

    &--primary {
      background-color: $primary-color;
      color: white;

      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }

    &--outline {
      border-color: $secondary-color;
      color: $secondary-color;
    }
  }
}

// Footer
.footer {
  background-color: $background-light;

  &__divider {
    margin: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 105px 12px 17px;
    position: fixed;
    bottom: 0px;
    background-color: #F7F7F7;
    width: 100%;
  }

  .status-container {
    display: flex;

    gap: 10px;
  }

  .submit-btn {
    width: 180px;
    height: 40px;
    background-color: $secondary-color;
    color: white;
    font-weight: 500;
    margin-right: 8px;

    span {
      font-size: 13px !important;
    }

    &:hover {
      background-color: $secondary-color !important;
      color: white !important;
    }
  }

  .status-tag {
    //padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    //text-transform: uppercase;
    font-size: 14px;
    //letter-spacing: 0.5px;
    // Status variants

    &.status-answered {
      background-color: #53B51B;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 8px;
    }

    &.status-current {
      background-color: #FA9B31;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 8px;
    }

    &.status-skipped {
      background-color: #31AAFA;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 8px;
    }

    &.status-flagged {
      background-color: #702FBA;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      margin-right: 8px;
    }
  }
}

.question-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 8px 0;

  &__question-number {
    font-size: 18px;
    font-weight: 500;
    //color: $text-color;
  }
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 4px;
}

.icon {
  width: 24px;
  height: 24px;
  color: #666;
  transition: all 0.2s ease-in-out;
}

.question-header {
  &__flag-button {
    width: 175px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background-color: white;
    border: 1px solid $border-color;
    padding: 0 16px;

    &:hover, &:active, &:focus {
      background-color: white;
      span {
        color: #262626;
      }
      svg {
        color: inherit;
      }
    }

    &.flagged {
      background-color: $amethyst-status;

      &:hover, &:active, &:focus {
        background-color: $amethyst-status !important;
        span {
          color: white !important;
        }
        svg {
          color: $primary-color;
          fill: $primary-color;
        }
      }

      span {
        color: white;
      }
    }

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
    }
  }
}

.summary-modal {

  .ant-modal-content {
    border-radius: 6px !important;
    padding: 32px !important;
  }

  .ant-modal-header {
    border-bottom: none;
    margin-bottom: 32px;
    padding: 0;

    .ant-modal-title {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      font-family: 'Poppins', sans-serif;
      //text-align: center;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 32px 0 0;
    margin-top: 0;
    //text-align: center;

    .summary-modal__cancel-btn {
      width: 128px;
      height: 44px;
      border: none;
      border-radius: 6px;
      margin-right: 8px;
      background-color: #333333;
      font-size: 14px;
      color: white;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;

      &:hover {
        color: white;
        background-color: #374151;
      }
    }

    .summary-modal__proceed-btn {
      width: 128px;
      height: 44px;
      border-radius: 6px;
      background-color: #FA9B31;
      border: none;
      color: white;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      &:hover {
        background-color: #FA9B31;
      }
    }
  }

  .summary-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    margin-bottom: 0;

    .stat-item {
      background-color: #f9fafb;
      padding: 20px 16px 20px 20px;
      position: relative;
      border: 1px solid #CACACA;
      border-radius: 6px;

      .stat-indicator {
        position: absolute;
        right: 24px;
        top: 22px;
        width: 4px;
        height: 19px;
        border-radius: 999px;

        &.answered { background-color: $green-status }
        &.skipped { background-color: $blue-status }
        &.flagged { background-color: $amethyst-status }
      }

      .stat-label {
        font-size: 13px;
        font-weight: 400;
        font-family: 'Poppins', sans-serif;
        color: #333333;
        margin-bottom: 8px;
      }

      .stat-value {
        font-size: 27px;
        font-weight: 400;
        color: #333333;
        line-height: 1;
      }
    }
  }
}

.confirmation-modal {
  // Remove fixed height and let content determine size
  border-radius: 6px;

  .ant-modal-content {
    border-radius: 6px;
    padding: 34px 47px 34px 34px !important;
    // Add box-shadow for better elevation
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  }

  .ant-modal-header {
    margin: 0 0 36px 0;
    padding: 0;
    border-bottom: none !important;
    .ant-modal-title {
      font-size: 20px;
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
      color: #1F2937;
    }
  }

  .ant-modal-body {
    padding: 0;

    .confirmation-content {
      p {
        margin: 0 0 12px 0;
        color: #4B5563;
        //line-height: 1.5;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .ant-modal-footer {
    margin-top: 40px;
    padding: 0;
    border-top: none;
    display: flex;
    justify-content: flex-end;
    gap: 16px;

    .confirmation-modal__back-btn {
      min-width: 100px;
      width: 150px;
      height: 48px;
      background: #333333;
      border: none;
      border-radius: 4px;
      color: white;
      font-weight: 400;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      &:hover {
        background: #333333;
        color: white;
      }
    }

    .confirmation-modal__submit-btn {
      min-width: 100px;
      width: 150px;
      height: 48px;
      background: #FA9B31;
      border: none;
      border-radius: 4px;
      color: white;
      font-weight: 400;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      transition: all 0.2s ease;

      &:hover {
        color: white;
        background: #FA9B31;
      }
    }
  }
}

// Add global centering fix
.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}