.custom-dropdown {
  overflow: hidden !important;
  border: 1px solid #CCCCCC;

  border-radius: 10px;
  .ant-dropdown-menu {
    padding: 0 10px 0 10px !important;
    border-radius: 4px !important;
    min-width: 160px !important;
    background: white !important;
    border: none !important;
    overflow: hidden !important;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -3px;
      right: 16px;
      width: 6px;
      height: 6px;
      background: white;
      transform: rotate(-45deg);
      z-index: 2;
      border-left: 1px solid #CCCCCC;
      border-top: 1px solid #CCCCCC;
      box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.03);
    }

    .ant-dropdown-menu-item {
      padding: 0 !important;
      border-radius: 8px !important;
      overflow-x: hidden !important;
      position: relative;
      z-index: 0;

      &:hover {
        background-color: #cacaca !important;
      }

      .custom-dropdown-item {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 10px 0px;
        font-size: 14px;
        color: #000000;
        font-weight: 400;
        border-bottom: 1px solid #CCCCCC;
        font-family: 'Poppins', sans-serif;

        svg {
          width: 18px;
          height: 18px;
          stroke-width: 2;
        }
      }

      &:last-child {
        .custom-dropdown-item {
          border-bottom: none;
        }
      }
    }

    span {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}

.custom-dropdown-trigger {
  padding: 4px !important;
  height: auto !important;
  border: none !important;

  &:hover {
    background-color: #f5f5f5 !important;
  }

  &:focus {
    background-color: #f5f5f5 !important;
  }

  svg {
    stroke-width: 2;
  }
}

.preview-modal {
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 12px !important;
    overflow: hidden !important;
  }
}