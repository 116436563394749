.dragging-from-menu [data-draggable="sortable"] {
    transform: none !important;
    transition: none !important;
    cursor: default;
  }
  
  /* Style for items being dragged from menu */
  [data-draggable="from-menu"] {
    z-index: 1000;
    position: relative;
  }
  body.dragging-from-menu {
    overflow: hidden;
  }

  .fieldBlock:hover svg path {
    fill: white;
    color: white;
  }
  .fieldBlock {
    transition: all 0.3s ease;
  }
  .custom-dashed-border {
    border: none;
    background-image: linear-gradient(to right, #ccc 50%, transparent 50%), 
                      linear-gradient(to bottom, #ccc 50%, transparent 50%), 
                      linear-gradient(to left, #ccc 50%, transparent 50%), 
                      linear-gradient(to top, #ccc 50%, transparent 50%);
    background-position: top, right, bottom, left;
    background-size: 8px 1px, 1px 8px, 8px 1px, 1px 8px;
    background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  }
  .dropdown-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    border-bottom: 1px solid #CACACA !important;
    color: #333333;
    font-size: 14px;
  
    &:last-child {
      border-bottom: none;
    }
  
    svg {
      width: 16px;
      height: 16px;
     
    }
  }
  
  .last-dropdown-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100% !important;
    color: #333333;
    font-size: 14px;
  
    svg {
      width: 16px;
      height: 16px;
  
    }
  }