
.question_right_tabs {
  
    .ant-tabs-nav-list{
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .ant-tabs-tab{
        flex: 1;
        justify-content: center;
        margin-left: 0 !important;
        text-transform: uppercase;
    }

    .ant-tabs-tab-active{
        border-bottom: 5px solid #333333;
        border-radius: 5px;
    }

    .ant-tabs-ink-bar-animated{
        display: none;
    }

    .ant-slider .ant-slider-handle::after {
        background-color: #FA9B31 !important;
    }
}

.answer_tabs {
    .ant-tabs-tab-active{
        border-bottom: 5px solid #333333;
    }
    .ant-tabs-ink-bar-animated{
        display: none;
    }

    .ant-tabs-tab{
        padding-left: 16px;
        padding-right: 16px;
    }
}



