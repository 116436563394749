.action-buttons {
  width: 1325px;
  padding: 0px;
  border-radius: 6px;
  margin-bottom: 16px;
  border: 1px solid #CACACA;
  color: #CACACA;
  background-color: #FCFCFC !important;
  .ant-btn {
    gap: 5px;
    //font-size: 14px !important;
    //font-family: 'Poppins', sans-serif !important;
    &:not(:disabled):hover {
      //background-color: #FFF1E1 !important; // Ensure it applies when not disabled
      span {
        color: black; // Set text color to red
      }
      button {
        span {
          color: #CACACA; // Set text color to red
          &:not(:disabled):hover {
            //color: red;
          }
        }
      }
    }
  }
}

.confirmation-modal {
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
  }

  .ant-modal-header {
    margin: 0;
    padding: 16px 24px;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;

    .ant-modal-title {
      font-size: 18px;
      font-weight: 500;
      color: #333;
      font-family: 'Poppins', sans-serif;
    }
  }

  .ant-modal-body {
    padding: 24px;
    font-family: 'Poppins', sans-serif;

    p {
      margin: 0;
      color: #666;
      font-size: 14px;
      line-height: 1.5;

      &:first-child {
        margin-bottom: 8px;
      }
    }
  }

  .ant-modal-footer {
    margin: 0;
    padding: 16px 24px;
    border-top: 1px solid #f0f0f0;

    .ant-btn {
      height: 36px;
      padding: 0 16px;
      font-size: 14px;
      border-radius: 4px;
      gap: 5px;
      font-family: 'Poppins', sans-serif;

      &-default {
        border: 1px solid #d9d9d9;
        color: #666;

        &:hover {
          color: #333333;
          border-color: #999;
        }
      }

      &-primary {
        background: #FFF1E1;
        border-color: #FFF1E1;

        &:hover {
          background: #FFF1E1;
          border-color: #FFF1E1;
        }
      }
    }
  }
}