.user-profile-form {
  width: 100%;
  margin: 0 auto;
  padding: 0.18rem 0rem;
  height: auto;
  position: relative;
  background-color: #ffffff; /* Ensure white background */
  border-radius: 0px 0px 6px 6px; /* Match design (0,0,6,6) */
  .avatar-section {
    display: flex;
    align-items: center;
    padding: 0px;
    margin-bottom: 0rem;

    .ant-row.ant-row-middle {
      margin-left: 4px !important;
    }
  }

  .vertical-divider {
    position: absolute;
    left: 39px; 
    top: 65px; 
    bottom: 30px; 
    height: 74%; 
    border-left: 1px solid #EAEAEA;

    @media (max-width: 576px) {
      left: 20px;
      top: 50px;
      bottom: 20px;
      height: 70%; 
    }

    @media (min-width: 577px) and (max-width: 768px) {
      left: 29px; 
      top: 60px; 
      bottom: 25px; 
      height: 72%; 
    }

    @media (min-width: 769px) and (max-width: 992px) {
      left: 31px;
      top: 49px;
      bottom: 30px;
      height: 80%;
    }

    @media (min-width: 993px) and (max-width: 1200px) {
      left: 30px;
      top: 49px;
      bottom: 30px;
      height: 80%;
    }
    @media (min-width: 1100)  {
      left: 39px;
      top: 65px; 
      bottom: 30px; 
      height: 74%; 
    }
    @media (min-width: 1201px) {
      left: 39px; 
      top: 65px; 
      bottom: 30px; 
      height: 74%; 
    }
  }

  .info-section {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    margin-bottom: 0rem;
    margin-left: 1rem;

    @media (max-width: 576px) {
      margin-left: 0.75rem;
    }

    @media (min-width: 1200px) {
      margin-left: 1.5rem;
      margin-bottom: 1px;
    }
  }

  .button-container {
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 1rem;
    margin-left: clamp(20px, 5%, 56px);
    max-width: 600px;
    font-family: 'Poppins', medium;

    // border-bottom: 1px solid #EAEAEA;

    @media (min-width: 1000px) and (max-width: 1100px) {
      margin-left: 40px;
    }

    @media (min-width: 1100px) and (max-width: 1200px) {
      margin-left: 45px;
    }

    @media (max-width: 576px) {
      margin-left: 20px;
      gap: 0.5rem;
    }

    @media (min-width: 577px) and (max-width: 768px) {
      margin-left: 30px;
    }

    @media (min-width: 769px) and (max-width: 992px) {
      margin-left: 40px;
    }

    @media (min-width: 993px) and (max-width: 1099px) {
      margin-left: 46px;
    }

    @media (min-width: 1201px) {
      margin-left: 56px;
    }

    .ant-btn {
      flex: 1 1 auto;
      font-weight: 500;
      transition: all 0.3s ease;

      &.reset-password-btn {
        width: 153px;
        height: 48px;
        border: 1px solid #333333; /* Default border color */
        color: #333333;
        margin-left: 26px;
        font-family: 'Poppins', medium;
        font-size: 14px;
        font-weight: 500;
      
        @media (max-width: 576px) {
          width: 100%;
          margin-left: 0;
        }
      
        &:hover {
          background-color: #fff5e6;
          color: #fa8c16;          /* Text turns orange */
          border-color: #fa8c16;   /* Border turns orange */
        }
      }
      &.sign-out-btn {
        width: 130px;
        height: 48px;
        background-color: #FA9B31;
        border-color: #FA9B31;
        color: #fff;
        font-family: 'Poppins', medium;
        font-size: 14px;
        font-weight: 500;

        @media (max-width: 576px) {
          width: 100%;
        }

        &:hover {
          background-color: #f68c16;
          border-color: #f68c16;
        }
      }

      @media (max-width: 576px) {
        width: 100% !important;
        height: 2.5rem;
        font-size: 0.75rem;
      }

      @media (min-width: 400px) and (max-width: 600px) {
        width: 100px !important;
        height: 36px !important;
        font-size: 0.75rem !important;
      }

      @media (min-width: 1200px) {
        height: 2.5rem;
        font-size: 0.875rem;
      }
    }
  }

  .ant-divider {
    margin: 0.5rem 0;
    border: 0.5px solid #eaeaeaf6;
  }

  .ant-divider-horizontal {
    margin: 0.5rem 0;
    border: 0px solid #eaeaeaf6;
  }

  .user-name {
    font-family: 'Poppins', openSans;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    display: block;
    color: #333333;

    &.strong {
      font-family: 'Poppins', openSans;
      font-size: 14px;
      font-weight: 400;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 576px) {
      font-size: 12px;
    }

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }
  .user-email {
    font-family: 'Poppins', openSans;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    display: block;
    color: #333;

    &.strong {
      font-family: 'Poppins', openSans;
      font-size: 14px;
      font-weight: 499;
    }

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 576px) {
      font-size: 12px;
    }

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  .user-role {
    font-family: 'Poppins', openSans;
    font-size: 14px;
    line-height: 0.875rem;

    &.secondary {
      color: #000000bf;
    }

    @media (max-width: 768px) {
      font-size: 0.6875rem;
    }

    @media (max-width: 576px) {
      font-size: 0.625rem;
    }

    @media (min-width: 1200px) {
      font-size: 0.85rem;
    }
  }

  .user-title {
    font-family: 'Poppins', openSans;
    font-size: 12px;
    line-height: 0.875rem;

    &.secondary {
      color: #333333;
    }

    @media (max-width: 768px) {
      font-size: 0.6875rem;
    }

    @media (max-width: 576px) {
      font-size: 0.625rem;
    }

    @media (min-width: 1200px) {
      font-size: 0.85rem;
    }
  }

  .ant-row.ant-row-middle {
    display: block !important;
    margin-left: 39px !important;
    margin-right: 0 !important;
    margin-top: 9px;

    @media (max-width: 1199px) {
      margin-left: clamp(1rem, 5vw, 2.4375rem) !important;
    }

    @media (min-width: 1200px) {
      margin-left: 46px !important;
    }
  }

  .ant-avatar {
    margin-top: 0.5rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    margin-bottom: 0;
    background-color: transparent; 
    outline: none;
    @media (max-width: 576px) {
      width: 1.75rem !important;
      height: 1.75rem !important;
      line-height: 1.75rem !important;
      font-size: 0.75rem !important;
      margin-top: 0.25rem;
      margin-left: 0.25rem;
      margin-right: 0.25rem;
    }

    @media (min-width: 1200px) {
      width: 63px !important;
      height: 63px !important;
      line-height: 2.5rem !important;
      margin-top: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }

  @media (max-width: 576px) {
    padding: 0.5rem 0.75rem;
  }

  @media (min-width: 1200px) {
    padding: 0.18rem 0rem;
    height: 267px;
  }
}

.main-header .ant-popover {
  width: 100% !important;
  max-width: 465px !important;
  height: auto !important;
  inset: 61px 1px auto auto !important;
  margin-top: -16px;
}

.main-header .custom-popover .ant-popover .ant-popover-inner {
  padding: 1px !important;
}
:where(.css-dev-only-do-not-override-7t9jjj).ant-popover .ant-popover-inner {
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0px 0px 6px 6px; /* Match design (0,0,6,6) */
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 12px;
}